import { render, staticRenderFns } from "./ScholarshipProgramBanner.vue?vue&type=template&id=451bc2cf&scoped=true&"
import script from "./ScholarshipProgramBanner.vue?vue&type=script&lang=ts&"
export * from "./ScholarshipProgramBanner.vue?vue&type=script&lang=ts&"
import style0 from "./ScholarshipProgramBanner.vue?vue&type=style&index=0&id=451bc2cf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "451bc2cf",
  null
  
)

export default component.exports