
import { Component, Vue } from 'nuxt-property-decorator'
import BaseRabotaAgroMainBanner from '@rshb/rshb-vue-kit/src/projects/rabotaAgro/banners/BaseRabotaAgroMainBanner.vue'
import BaseRabotaAgroIconButton from '@rshb/rshb-vue-kit/src/projects/rabotaAgro/buttons/BaseRabotaAgroIconButton.vue'
import SwiperContainer from '~/components/Containers/SwiperContainer.vue'
import ScholarshipProgramBanner from '~/components/Page/ScholarshipProgram/ScholarshipProgramBanner.vue'
import MainAgroBanner from '~/components/Page/Main/MainAgroBanner.vue'
import CareerMarathonMainBanner from '~/components/Banners/CareerMarathonMainBanner.vue'

@Component({
  components: {
    MainAgroBanner,
    ScholarshipProgramBanner,
    SwiperContainer,
    BaseRabotaAgroMainBanner,
    BaseRabotaAgroIconButton,
    CareerMarathonMainBanner
  }
})
export default class MainBanner extends Vue {
  banner: string = '/images/main-banner.png'
  mainBannerImage: string = '/images/main/mainBanner.png'
  mainBannerTabletImage: string = '/images/main/mainBannerTablet.png'
  mainBannerImageMobile: string = '/images/main/mainBannerImageMobile.jpg'
  mainBannerImageMobileSecond: string = '/images/main/mainBannerImageMobileSecond.jpg'
  value = ''

  banners = [{}]

  setValue(value) {
    this.value = value
  }

  searchVacansy() {
    const val = this.value
    this.$tstore.search.setSearch(val)
    const region = this.$services.geolocation.getCookieRegion()

    if (region) {
      const pathRoute = region.slug === 'russia' ? '/vacancies' : `/vacancies/region-${region.slug}`
      this.$router.push({
        path: pathRoute,
        query: { search: val }
      })
    } else {
      this.$router.push({
        path: '/vacancies',
        query: { search: val }
      })
    }
  }

  desktopSliderOptions = {
    loop: false,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: '#ps-next',
      prevEl: '#ps-prev'
    },
    pagination: {
      el: '.swiper-pagination'
    }
  }

  clickApplication() {
    this.$router.push('/events/scholarship-program')
  }
}
